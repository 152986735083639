import { createFeature, createReducer, on } from '@ngrx/store';
import { ScriptActions } from './script.actions';
import { DialerScript } from 'src/app/shared/models';

export const scriptFeatureKey = 'script';

export interface ScriptState {
  script: DialerScript | null;
  scripts: DialerScript[];
  loading: boolean;
  error: any;
}

export const initialState: ScriptState = {
  script: null,
  scripts: [],
  loading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    ScriptActions.loadScripts,
    (state): ScriptState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    ScriptActions.loadScriptsSuccess,
    (state, { scripts }): ScriptState => ({
      ...state,
      scripts: [...state.scripts.filter((item) => !scripts.find(x => x.id === item.id)), ...scripts],
      loading: false,
      error: null,
    }),
  ),
  on(
    ScriptActions.loadScriptsFailure,
    (state, { error }): ScriptState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    ScriptActions.loadScript,
    (state): ScriptState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    ScriptActions.loadScriptSuccess,
    (state, { script }): ScriptState => ({
      ...state,
      scripts: [...state.scripts.filter((item) => script.id === item.id), script],
      script: script,
      loading: false,
      error: null,
    }),
  ),
  on(
    ScriptActions.loadScriptFailure,
    (state, { error }): ScriptState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    ScriptActions.addScript,
    (state): ScriptState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    ScriptActions.addScriptSuccess,
    (state, { script }): ScriptState => ({
      ...state,

      scripts: [...state.scripts, script],

      loading: false,

      error: null,
    }),
  ),
  on(
    ScriptActions.addScriptFailure,
    (state, { error }): ScriptState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    ScriptActions.removeScript,
    (state): ScriptState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    ScriptActions.removeScriptSuccess,
    (state, { scriptId }): ScriptState => ({
      ...state,
      scripts: state.scripts.filter(
        (script) => script.id !== scriptId,
      ),
      loading: false,
      error: null,
    }),
  ),
  on(
    ScriptActions.removeScriptFailure,
    (state, { error }): ScriptState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
  on(
    ScriptActions.updateScript,
    (state): ScriptState => ({
      ...state,
      loading: true,
      error: null,
    }),
  ),
  on(
    ScriptActions.updateScriptSuccess,
    (state, { script }): ScriptState => ({
      ...state,
      scripts: state.scripts.map((item) =>
        // item.id === script.id ? script : item
        item.id === script.id ? { ...item, ...script } : item,
      ),
      loading: false,
      error: null,
    }),
  ),
  on(
    ScriptActions.updateScriptFailure,
    (state, { error }): ScriptState => ({
      ...state,
      loading: false,
      error,
    }),
  ),
);

export const scriptFeature = createFeature({
  name: scriptFeatureKey,
  reducer,
});
