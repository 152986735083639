import { createActionGroup, props } from '@ngrx/store';
import { DialerScript } from 'src/app/shared/models';

export const ScriptActions = createActionGroup({
  source: 'Script',
  events: {
    'Load Scripts': props<{ agencyId: string }>(),
    'Load Scripts Success': props<{ scripts: DialerScript[] }>(),
    'Load Scripts Failure': props<{ error: any }>(),
    'Load Script': props<{ scriptId: string; agencyId: string }>(),
    'Load Script Success': props<{ script: DialerScript }>(),
    'Load Script Failure': props<{ error: any }>(),
    'Add Script': props<{ script: DialerScript; agencyId: string }>(),
    'Add Script Success': props<{ script: DialerScript }>(),
    'Add Script Failure': props<{ error: any }>(),
    'Remove Script': props<{ scriptId: string; agencyId: string }>(),
    'Remove Script Success': props<{ scriptId: string }>(),
    'Remove Script Failure': props<{ error: any }>(),
    'Update Script': props<{
      scriptId: string;
      agencyId: string;
      merge?: boolean;
      script: Partial<DialerScript>;
    }>(),
    'Update Script Success': props<{
      scriptId: string;
      agencyId: string;
      script: Partial<DialerScript>;
    }>(),
    'Update Script Failure': props<{ error: any }>(),
  },
});
